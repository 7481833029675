// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap');

@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables
@import 'css_web';
@import 'font';

// Animaciones
@import "~animate.css/animate";


// Fontawesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// Quill
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css'; //theme


// Default theme
@import '~@splidejs/splide/dist/css/themes/splide-default.min.css';

// Components
:root{
	--azul: #6DC3D6;
	--purple: #4b10e9;
}

.text-purple{
	color: var(--purple);
}

.text-azul{
	color: var(--azul);
}

.component_content{
	width: auto;
	height: auto;
}
.component_content span.component_title{
	display: inline-flex;

	width: auto;
	height: auto;
	background-color: #4b10e9;

	color: #fff;
	// font-size: 7.63pt !important;
	font-weight: bold;

	padding: 0 0.4rem;
	border-radius: 5px;

	transform: rotateZ(-4deg);

	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.20);
}

.component_content span.component_title#title_1_1{
	font-size: 2rem;
}

r.circle{
	font-family: 'Lato', sans-serif;
	font-weight: 900;
    margin-right: 5px;
}

.component_content span.component_title#title_2_1{
	font-size: 1.7rem;
    margin: -1rem 0 1rem;
}

.component_content span.component_title#title_3_1{
	font-size: 2.2rem;
    margin: 0px 0 1rem;
}

.component_content span.component_title#title_4_1{
	font-size: 2.2rem;
}

.component_content span.component_title#title_5_1{
	font-size: 2.2rem;
}

.component_content span.component_title#title_6_1{
	font-size: 2.6rem;
    margin-bottom: 2rem;
}

@media (max-width: 440px){
	.component_content span.component_title#title_2_1{
		font-size: 1.3rem;
		margin: -1rem 0 1rem;
	}
}

@media (max-width: 768px){
	.component_content span.component_title#title_3_1{
		font-size: 2rem;
	}
	.component_content span.component_title#title_4_1{
		font-size: 1.4rem;
	}
	.component_content span.component_title#title_5_1{
		font-size: 1.8rem;
	}
}

@media (max-width: 440px){
	#nostros .component_content span.component_title#title_5_1{
		font-size: 1.6rem;
	}
}
