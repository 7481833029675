/* -------------------------------------------------------------------------- */
/*                                 Font Gotham                                */
/* -------------------------------------------------------------------------- */

/* --------------------------------- Italic --------------------------------- */
	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 100;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-ThinItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-ThinItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 200;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-XLightItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-XLightItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 300;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-LightItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-LightItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 400;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-BookItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-BookItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 500;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-MediumItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-MediumItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 600;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-BoldItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-BoldItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 700;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-BlackItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-BlackItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: italic;
		font-weight: 800;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-UltraItalic.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-UltraItalic.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
/* -------------------------------------------------------------------------- */


/* --------------------------------- Normal --------------------------------- */
	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 100;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Thin.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Thin.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 200;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-XLight.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-XLight.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 300;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Light.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Light.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Book.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Book.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Medium.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Medium.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Bold.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 700;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Black.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Black.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gotham';
		font-style: normal;
		font-weight: 800;
		font-display: swap;
		src: url('/fonts/Gotham/Gotham-Ultra.woff2') format('woff2'),
			url('/fonts/Gotham/Gotham-Ultra.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
/* -------------------------------------------------------------------------- */


	@font-face {
		font-family: 'Camelleon';
		font-style: normal;
		font-weight: 100;
		font-display: swap;
		src: url('/fonts/Camelleon/Camelleon.woff2') format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'BebasNeue';
		font-style: normal;
		font-weight: 100;
		font-display: swap;
		src: local('BebasNeue'), url('/fonts/BebasNeue/BebasNeue-Bold.otf') format('truetype');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
