

/* ---------------------------------- Menu ---------------------------------- */

	nav.navbar{
		-webkit-box-shadow: 0px 3px 19px -5px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 3px 19px -5px rgba(0,0,0,0.75);
		box-shadow: 0px 3px 19px -5px rgba(0,0,0,0.75);
	}

	nav.navbar{
		font-family: 'Gotham';
	}

	.fixed-top {
		position: fixed;
		top: -80px;
		right: 0;
		left: 0;
		z-index: 1030;
		transition: .3s;
	}

	.fixed-top.active {
		top: 0px;
	}

	// purecounter

/* -------------------------------------------------------------------------- */

/* --------------------------------- Header --------------------------------- */

	#inicio{
		width: 100%;
		background-color: #FFF;
		overflow: hidden;
	}

	.home{
		width: 100%;
		height: 100vh;
		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.home span.loading{
		display: flex;
		justify-content: center;
		position: absolute;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: #2c2c2c;
		background-size: 7vw;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('/img/loading.svg');
		z-index: 10;
		animation-delay: 1.1s;
		pointer-events: none;
	}

	.home p.we-are{
		display: block;
		position: absolute;
		top: 10vh;
		left: 10%;
		width: 80%;
		height: 18vh;
		z-index: 1;
		animation-duration: 3s;
	}

	.home p.we-are span{
		display: block;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/WE_ARE.svg');
	}

	.home .boat{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 70vh;
		top: 17vh;
		left: 0;
		z-index: 2;
		animation-duration: 3s;
	}

	.home .boat video,
	.home .boat span{
		width: auto;
		height: 100%;
	}

	.home .boat span.boatimg{
		width: 100% !important;
		display: block;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/barco.webp');
	}

	.home a.down{
		position: absolute;
		bottom: 5vh;
		left: calc(50% - 15px);
		animation: btn-down-animation 1.5s infinite;
	}

	.btn-down{
		display: block;
		width: 30px;
		height: 30px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/down.svg');
	}

	@keyframes btn-down-animation {
		0% {
			opacity: 0;
			bottom: 5vh;
		}
		25% {
			opacity: 1;
		}
		75% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			bottom: 2vh;
		}
	}

	.home p.slogan{
		position: absolute;
		bottom: 10vh;
		left: 10%;
		width: 80%;
		height: 30px;
		z-index: 5;
	}

	.home p.slogan span{
		display: block;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('/img/sligan.svg');
	}

	.home p.cloud-back{
		display: block;
		position: absolute;
		bottom: 15vh;
		left: 0;
		width: 100%;
		height: 22vh;
		z-index: 3;
		animation-duration: 3s;
	}

	.home p.cloud-back span.cloud2{
		position: absolute;
		top: 0;
		display: block;
		width: 200%;
		height: 100%;
		background-size: auto 100%;
		background-repeat: repeat-x;
		background-image: url('/img/clouds/2.webp');
		/* border: 1px solid red; */
		animation: animation-cloud 400s linear 1.2s infinite;
	}

	.home p.cloud{
		display: block;
		position: absolute;
		bottom: 15vh;
		left: 0;
		width: 150%;
		height: 22vh;
		z-index: 4;
		animation-duration: 3s;
	}

	.home p.cloud span.cloud1{
		position: absolute;
		top: 0;
		display: block;
		width: 200%;
		height: 100%;
		background-size: auto 100%;
		background-repeat: repeat-x;
		/* border: 1px solid blue; */
		background-image: url('/img/clouds/1.webp');
		animation: animation-cloud 200s linear 1.2s infinite;
	}

	@keyframes animation-cloud{
		0%{
			left: -100%;
		}
		// 50%{
		// 	left: 10%;
		// }
		100%{
			left: 20%;
		}
	}

	@media (max-width: 800px){
		.home p.slogan{
			height: 80px;
		}
		.home p.slogan span{
			background-image: url('/img/sligan_l.svg');
		}
	}
	@media (max-width: 768px){
		.home p.cloud-back span{
			animation: animation-cloud 180s linear 1.2s infinite;
		}

		.home p.cloud span{
			animation: animation-cloud 100s linear 1.2s infinite;
		}
	}

	@media (max-width: 600px){
		.home .boat{
			height: 55vh;
			top: 20vh;
		}

		.home .cloud{
			bottom: 20vh;
		}
	}

	@media (max-width: 500px){
		.home p.cloud-back span{
			animation: animation-cloud 140s linear 1.2s infinite;
		}

		.home p.cloud span{
			animation: animation-cloud 60s linear 1.2s infinite;
		}
	}

	@media (max-width: 450px){
		.home .boat{
			height: 48vh;
			top: 20vh;
		}

		.home .cloud{
			bottom: 30vh;
		}

		.home p.cloud-back span{
			animation: animation-cloud 80s linear 1.2s infinite;
		}

		.home p.cloud span{
			animation: animation-cloud 40s linear 1.2s infinite;
		}
	}


/* -------------------------------------------------------------------------- */



/* ---------------------------------- intro --------------------------------- */

	#intro{
		background-color: #FFF;
	}

	#intro .award{
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		margin: 50px 0;
	}

	#intro .award span{
		display: block;
		width: 100%;
		height: 100px;
		background-position: center;
		background-size: auto 100%;
		background-repeat: no-repeat;
	}

	#intro span.img_somos{
		display: block;
		width: 100%;
		height: 500px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/es/somos.svg');
	}

	.award-sm{
		display: none;
	}

	@media (max-width: 991px){
		.award-lg{
			display: none !important;
		}
		.award-sm{
			display: block;
		}
		#intro #carousel_premios .award{
			margin: 20px 0;
		}
		#intro #carousel_premios .award span{
			height: 150px;
		}
	}

	@media (max-width: 767px){
		#intro .text-general{
			font-size: 1.7em;
		}
	}
	@media (max-width: 580px){
		#intro{
			padding: 0 30px;
		}
		#intro span.img_somos{
			height: 350px;
		}
	}
	@media (max-width: 490px){
		#intro .text-general{
			font-size: 1.45em;
		}
	}
	@media (max-width: 420px){
		#intro .text-general{
			font-size: 1em;
		}
		#intro .text-general-sm{
			font-size: 1em;
		}
	}

/* -------------------------------------------------------------------------- */




/* -------------------------------- Servicios ------------------------------- */


	#servicioss{
		min-height: 500px;
		background-position: center;
		background-size: cover;
		background-image: url('/img/bg-servicios.webp');
		padding-bottom: 20px;
	}

	#carousel_servicios .card{
		width: 80%;
		max-width: 900px;
		margin: auto;

		border-radius: 15px;
		-webkit-box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.75);
		-moz-box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.75);
		box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.75);
	}

	#carousel_servicios .carousel-inner,
	#carousel_servicios .splide__slide{
		padding: 80px 0 30px 0;
	}

	#carousel_servicios .card .card-body{
		padding: 80px 70px 30px 70px;
		position: relative;
		min-height: 431px;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column nowrap;
	}

	#carousel_servicios .card .card-body .box-texts{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: column nowrap;
	}

	#carousel_servicios .card .card-body .card-text{
		font-size: 2em;
		font-weight: 500;
		line-height: 1.2em;
		// text-align: center;
	}

	#carousel_servicios .card .card-body .card-text b{
		font-weight: 900;
		color: #4B10E9;
	}

	.splide__arrow {
		width: 5em;
		height: 100%;
		border-radius: 0%;
		background: rgba(204, 204, 204, 0);
	}
	.splide__arrow span{
		width: 5rem;
		height: 5rem;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	.splide__arrow--next span.next-icon{
		background-image: url('/img/services/arrowR.svg');
	}

	.splide__arrow--prev span.prev-icon{
		background-image: url('/img/services/arrowL.svg');
	}

	.img-services{
		position: absolute;
		top: -75px;
		left: calc(50% - 75px);
		width: 150px;
		height: 150px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	.img-services.img-1{
		background-image: url('/img/services/icon_1.png');
	}
	.img-services.img-2{
		background-image: url('/img/services/icon_2.png');
	}
	.img-services.img-3{
		background-image: url('/img/services/icon_3.png');
	}
	.img-services.img-4{
		background-image: url('/img/services/icon_4.png');
	}

	span.title-servicios{
		width: 100%;
		height: 130px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	span.title-servicios.text-sm{
		display: none;
	}

	span.title-servicios.text-lg{
		display: block;
	}

	@media (max-width: 1035px){
		#carousel_servicios .card .card-body .card-text{
			font-size: 1.5em;
		}
	}
	@media (max-width: 991px){
		.title-servicios.text-lg{
			display: none !important;
		}
		.title-servicios.text-sm{
			display: block !important;
		}
	}
	@media (max-width: 900px){
		.splide__arrow{
			width: 4em;
		}
		.splide__arrow--next{
			right: .1em;
		}
		.splide__arrow--prev{
			left: .1em;
		}
	}
	@media (max-width: 767px){
		.splide__arrow{
			width: 2.5em;
		}
		#servicios .container{
			max-width: 100%;
		}

		#carousel_servicios .card {
			width: 85%;
		}
		#carousel_servicios .card .card-body {
			min-height: 390px;
		}
		span.title-servicios{
			height: 80px;
		}
	}
	@media (max-width: 550px){
		#carousel_servicios .card .card-body {
			padding: 90px 10px 30px 10px;
		}
	}

	@media (max-width: 490px){
		.splide__arrow{
			width: 1.5em;
		}
		#carousel_servicios .card .card-body .card-text{
			font-size: 1.3em;
		}
		#carousel_servicios .card .card-body {
			min-height: 340px;
		}
	}

	@media (max-width: 420px){
		#carousel_servicios .card .card-body .card-text{
			font-size: 1.13em;
		}
		#carousel_servicios .card .card-body {
			min-height: 300px;
		}
	}


/* -------------------------------------------------------------------------- */


/* ---------------------------- Numeros servicios --------------------------- */

	.bg_menta{
		background-color: #45c5d9;
	}

	#boxCounterServices .h1{
		font-size: 4em;
		font-weight: bold;
		margin: 0 0 -5px 0;
	}

	#boxCounterServices #counter_audiovisuales{
		min-width: 214px;
	}

	#boxCounterServices #counter_satisfechos{
		width: 190px;
	}

	#boxCounterServices #counter_digitales{
		width: 190px;
	}

/* -------------------------------------------------------------------------- */



/* --------------------------------- Aliados -------------------------------- */

	#aliados{
		background-color: #FFF;
	}

	#aliados .img_aliados{
		display: block;
		width: 100%;
		height: 400px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/aliados2.svg');
	}

	@media (max-width: 770px){
		#aliados .img_aliados{
			width: 100%;
			height: 500px;
			background-image: url('/img/aliados2_v.svg');
		}
	}

	@media (max-width: 440px){
		#aliados .img_aliados{
			width: 100%;
			height: 400px;
			background-image: url('/img/aliados2_v.svg');
		}
	}

	.bg_world{
		position: relative;
		display: block;
		width: 100%;
		min-height: 250px;
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #333;
		color: #45c5d9;
		background-image: url('/img/bg_aliados.jpg');
	}

	.video_world{
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
		z-index: 0;
		pointer-events: none;
	}

/* -------------------------------------------------------------------------- */



/* ----------------------------- Casos de exito ----------------------------- */

	#casos_exito{
		background-color: #FFF;
		max-width: 800px;
		margin: auto;
	}

	#casos_exito a{
		color: #4b11e9 !important;
		font-style: italic !important;
	}

	// .img_tiktok{
	// 	display: block;
	// 	width: 50%;
	// 	// height: 150px;
	// 	background-position: center;
	// 	background-size: contain;
	// 	background-repeat: no-repeat;
	// 	background-image: url('/img/tiktok.png');
	// }
	img.img_tiktok{
		height: auto;
		width: 65%;
		margin: 10px auto;
	}

	img.see_more-tiktok{
		width: 30%;
	}

	.text-tiktokMKP p{
		padding: 0 0 0 30px;
		position: relative;
		font-weight: 500;
		line-height: 1.2;
		font-size: 1.5em;
	}

	.text-tiktokMKP p::before{
		content: '✨';
		font-size: 2em;
		position: absolute;
		left: -25px;
		top: -10px;
	}

	.bg-maderas{
		width: 100%;
		min-height: 600px;
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #FFF;
		background-image: url('/img/bg-tiktoks.webp');
		padding-bottom: 150px;
		position: relative;
	}

	.bg-maderas::after{
		content: '';
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-size: contain;
		background-repeat: repeat-x;
		background-image: url('/img/bg-all-in-one1.webp');
	}

	.img-tiktok-example{
		display: block;
		width: 100%;
		height: 450px;
		margin-top: 30px;
		background-position: bottom;
		background-size: contain;
		background-repeat: no-repeat;
		position: relative;
	}

	.img-tiktok-example.examples-1{
		background-image: url('/img/tiktok/phone_3.svg');
	}
	.img-tiktok-example.examples-2{
		background-image: url('/img/tiktok/phone_1.svg');
	}
	.img-tiktok-example.examples-3{
		background-image: url('/img/tiktok/phone_2.svg');
	}

	.img-tiktok-example::after{
		position: absolute;
		content: '';
		top: 10%;
		width: 35%;
		height: 80%;
		// background-color: #4a10e988;
		// border: 1px solid red;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.img-tiktok-example.examples-1::after,
	.img-tiktok-example.examples-3::after{
		left: 0%;
	}

	.img-tiktok-example.examples-2::after{
		right: 0%;
	}

	.img-tiktok-example.es.examples-1::after,
	.img-tiktok-example.es.examples-3::after{
		background-image: url('/img/tiktoks/es/r.svg');
	}

	.img-tiktok-example.es.examples-2::after{
		background-image: url('/img/tiktoks/es/l.svg');
	}

	.img-tiktok-example.en.examples-1::after,
	.img-tiktok-example.en.examples-3::after{
		background-image: url('/img/tiktoks/en/r.svg');
	}

	.img-tiktok-example.en.examples-2::after{
		background-image: url('/img/tiktoks/en/l.svg');
	}

	.img-tiktok-example.cn.examples-1::after,
	.img-tiktok-example.cn.examples-3::after{
		background-image: url('/img/tiktoks/cn/r.svg');
	}

	.img-tiktok-example.cn.examples-2::after{
		background-image: url('/img/tiktoks/cn/l.svg');
	}

	.img-tiktok-example.br.examples-1::after,
	.img-tiktok-example.br.examples-3::after{
		background-image: url('/img/tiktoks/br/r.svg');
	}

	.img-tiktok-example.br.examples-2::after{
		background-image: url('/img/tiktoks/br/l.svg');
	}


	@media (max-width: 991px) {
		.bg-madera{
			background-image: url('/img/Bg_tiktoks_v.jpg');
		}
		.text-tiktokMKP p{
			padding: 0 0 0 30px;
			position: relative;
			font-weight: 500;
			line-height: 1.2;
			font-size: 1em;
		}
		.text-tiktokMKP p::before{
			left: 0;
		}
	}

	@media (max-width: 580px){
		#casos_exito{
			padding: 0 30px;
		}

		.bg-maderas{
			padding-bottom: 100px;
		}

		.bg-maderas::after{
			bottom: -30px;
		}
	}

	@media (max-width: 440px){
		.text-tiktokMKP p,
		#nosotros .text-general{
			font-size: 1em !important;
		}

		.text-tiktokMKP{
			width: 330px;
		}

		.text-tiktokMKP p::before{
			left: 0px;
		}
	}


/* -------------------------------------------------------------------------- */



/* ------------------------------- All In One ------------------------------- */

#all-in-one{
	background-color: #FFF;
	background-position: left 35%;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url('/img/bg-all-in-one2_big.webp');
	padding-bottom: 30px;
	position: relative;
	z-index: 10;
}

#all-in-one::after{
	content: '';
	position: absolute;
	bottom: -70px;
	left: 0;
	width: 100%;
	height: 90px;
	background-position: bottom center;
	background-size: auto 150%;
	background-repeat: repeat-x;
	background-image: url('/img/bg-all-in-one3v2.webp');
	z-index: -1;
}

#all-in-one img.rs{
	content: url('/img/all-in-one_big.webp');
	width: 50%;
	max-width: 50%;
	margin: 30px auto;
}

#all-in-one a img{
	max-width: 160px;
}

#all-in-one h5{
	color: #4b10e9;
    font-size: 1.8em;
    font-weight: 600;
}

// Responsive
@media (max-width: 768px){
	#all-in-one{
		background-position: left 35%;
		background-size: auto 38%;
		background-image: url('/img/bg-all-in-one2.webp');
	}
	#all-in-one img.rs{
		content: url('/img/all-in-one.webp');
		max-width: 50%;
	}
}

@media (max-width: 440px){
	#all-in-one .text-general{
		font-size: 1em;
	}

	#all-in-one h5{
		font-size: 1.7em !important;
		margin-bottom: 0;
	}

	#all-in-one{
		background-size: auto 48%;
	}
	#all-in-one img.rs{
		max-width: 48%;
	}
}


/* -------------------------------------------------------------------------- */


/* ------------------------ Leading creator marketing ----------------------- */

#Leading-creator-marketing{
	width: 100%;
	min-height: 500px;
	background-color: #45c5d9;
	position: relative;
	z-index: 9;

	background-position: center 72%;
	background-size: 60% auto;
	background-repeat: no-repeat;
	background-image: url('/img/leading/bg.webp');
}

#Leading-creator-marketing img.phones{
	width: 90%;
	margin-top: 50px
}

#Leading-creator-marketing .col-4{
	padding: 0;
}

#Leading-creator-marketing .text-general{
	color: #4b10e9;
	font-size: 1.5em;
	font-weight: 600;
}

@media (max-width: 768px){
	#Leading-creator-marketing{
		background-size: 110% auto;
	}
}

@media (max-width: 440px){
	#Leading-creator-marketing img.phones{
		width: 100%;
	}
	#Leading-creator-marketing .title-vnkg{
		font-weight: 500;
	}
}

/* -------------------------------------------------------------------------- */


/* ------------------------------ Somo vikingos ----------------------------- */

	#nosotros{
		background-color: #FFF;
	}

	#nosotros .title-vnkg{
		text-transform: uppercase;
	}

	#nosotros .text-general{
		width: 330px;
		margin: 0 auto;
	}

	.img_dragon{
		display: block;
		width: 100%;
		height: 200px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/somos/dragon.gif');
	}

	.img_nosotros{
		display: block;
		width: 100%;
		height: 600px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.img_nosotros.nosotross{
		background-image: url('/img/somos/img_1.webp'); //!cambios de clase!!!
	}

	.img_nosotros.visions{
		background-image: url('/img/somos/img_2.webp'); //!cambios de clase!!!
	}

	.img_nosotros.extra{
		background-image: url('/img/somos/img_3.webp'); //!cambios de clase!!!
	}

	span.vision-mision-letter{
		display: block;
		width: 100%;
		height: 200px;
		margin-bottom: 15px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	span.vision-mision-letter.es{
		background-image: url('/img/manifiesto/es/vision.svg');
	}
	span.vision-mision-letter.en{
		background-image: url('/img/manifiesto/en/vision.svg');
	}
	span.vision-mision-letter.cn{
		background-image: url('/img/manifiesto/cn/vision.svg');
	}
	span.vision-mision-letter.br{
		background-image: url('/img/manifiesto/br/vision.svg');
	}

	.img_divider{
		display: block;
		width: 100%;
		height: 700px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/somos/img_4.webp');
	}

	#vision_3{
		padding: 40px 0;
	}

	#mision_2 img,
	#vision_1 img,
	#cultura_2 img,
	#vision_3 img,
	.text-sm{
		display: none;
	}

	@media (max-width: 1000px){
		.img_divider{
			height: 400px;
		}
	}

	@media (max-width: 991px){
		.text-lg{
			display: none;
		}
		.text-sm{
			display: inline-block;
		}
		#mision_1,
		#vision_2,
		#cultura_1,
		#vision_3 span{
			display: none;
		}
		#vision_3{
			padding: 0;
		}

		#mision_2 img,
		#vision_1 img,
		#cultura_2 img,
		#vision_3 img{
			display: block;
			margin-bottom: 15px;
		}

		#mision_2 img{
			margin: 25px 0 !important;
		}

		#vision_1 img{
			margin: 10px 0 25px 0 !important;
		}

		#cultura_2 img{
			margin: 10px 0 25px 0 !important;
		}

		#vision_3{
			margin-bottom: 0;
		}
		#vision_3 p{
			width: 90%;
			margin: auto;
		}
	}

	@media (max-width: 680px){
		#nosotros .text-general{
			font-size: 1.5em;
		}
	}
	@media (max-width: 490px){
		#nosotros .text-general{
			font-size: 1.2em;
		}
	}
	@media (max-width: 400px){
		#nosotros .text-general{
			font-size: 1.1em;
		}
	}
	@media (max-width: 767px){
		span.vision-mision-letter{
			height: 400px;
		}

	}

	@media (max-width: 580px){
		// span.vision-mision-letter{
			// height: 350px;
		// }
		span.vision-mision-letter.es{
			background-image: url('/img/manifiesto/es/vision_v.svg');
		}
		span.vision-mision-letter.en{
			background-image: url('/img/manifiesto/es/vision_v.svg');
		}
		span.vision-mision-letter.cn{
			background-image: url('/img/manifiesto/es/vision_v.svg');
		}
		span.vision-mision-letter.br{
			background-image: url('/img/manifiesto/es/vision_v.svg');
		}
		.img_divider{
			background-size: cover;
		}
	}

/* -------------------------------------------------------------------------- */


/* --------------------------------- General -------------------------------- */

	.space-menu{
		padding-top: 100px;
	}

	.color-vkng-blue{
		color: #45c5d9;
	}

	.title-vnkg{
		font-family: 'Gotham';
		color: #45c5d9;
		font-size: 4em;
		font-weight: 600;
	}

	.text-general{
		text-decoration: none;
		font-family: 'Gotham';
		font-size: 2em;
		font-weight: 500;
		line-height: 1.2em;
	}

	.text-general b{
		font-weight: 800;
	}

	.text-general-sm{
		font-family: 'Gotham';
		font-size: 1.5em;
		font-weight: 400;
		line-height: 1.2em;
	}

	.text-general b{
		font-weight: 700;
	}

	span.text-sexy{
		font-family: 'Camelleon';
		color: #45c5d9;
	}

	main.content,
	.div-white{
		width: 100%;
		background-color: #FFF;
	}

	@media (max-width: 580px){
		.space-menu{
			padding-top: 70px;
		}
		.title-vnkg{
			font-size: 3em;
		}

		.text-general{
			font-size: 1.5em;
		}
	}

	@media (max-width: 440px){
		.text-general{
			font-size: 1.25em;
		}
		#boxCounterServices .h1{
			font-size: 3.2em;
		}
		.title-vnkg{
			font-size: 1.8em;
			line-height: 1;
		}
	}

/* -------------------------------------------------------------------------- */


/* -------------------------------- Siguenos -------------------------------- */

	#siguenos{
		background-color: #FFF;
		max-width: 100%;
		overflow: hidden;
	}

	.bg_siguenos{
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		min-height: 800px;
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #FFF;
		background-image: url('/img/bg-siguenos.png');
	}

	#siguenos .img-head{
		width: 100%;
		height: 80px;
		display: block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0;
		padding: 0;
	}

	#siguenos .img-head.img1{
		background-image: url('/img/siguenos/icon_1.png');
	}
	#siguenos .img-head.img2{
		background-image: url('/img/siguenos/icon_2.png');
	}
	#siguenos .img-head.img3{
		background-image: url('/img/siguenos/icon_4.png');
	}
	#siguenos .img-head.img4{
		background-image: url('/img/siguenos/icon_3.png');
	}
	#siguenos .img-head.img5{
		background-image: url('/img/siguenos/icon_5.png');
	}

	.tiktok-embed{
		display: flex!important;
		justify-content: center!important;
		margin: 0!important;
	}

	.tiktok-embed iframe,
	.instagram-media{
		max-width: 540px;
		overflow: hidden;
	}

	div.caja-iphone{
		position: relative;
		display: flex;
		justify-content: center;
		align-items: start;
		width: 400px;
		height: 810px;
		overflow: auto;
	}

	div.caja-iphone .phone{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		border-radius: 5px;
		background-image: url('/img/iPhone.png');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		z-index: 20;
		pointer-events: none;
	}

	div.caja-iphone .content{
		position: absolute;
		top: 19px;
		left: 22px;
		overflow: hidden;
		width: 356px;
		height: 772px;
		background-color: #fff;
		border-radius: 43px;
		z-index: 10;
	}
	a.iphone-OpenSean{
		display: block;
		width: 814px;
		height: 400px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		background-image: url('/img/OpenSean.png');
	}

/* -------------------------------------------------------------------------- */



/* -------------------------------- Contacto -------------------------------- */


	#contacto{
		position: relative;
		width: 100%;
		min-height: 100px;
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #2c2c2c;
		// background-image: url('/img/contact/bg_contact.png');
	}

	// #contacto::before{
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	top: -125px;

	// 	width: 100%;
	// 	height: 125px;
	// 	background-repeat: no-repeat;
	// 	background-size: auto 100%;
	// 	background-image: url('/img/liquid.svg');
	// 	animation: animation-liquid 20s linear infinite;
	// }

	// @keyframes animation-liquid{
	// 	0%{
	// 		background-position: right center;
	// 	}
	// 	50%{
	// 		background-position: left center;
	// 	}
	// 	100%{
	// 		background-position: right center;
	// 	}
	// }

	#contacto .vacante,
	#contacto .contact{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column nowrap;

		width: 100%;
		max-width: 500px;
		height: 100px;
		border: 0;
		border-radius: 0;
		cursor: pointer;

		background-color: rgba(0, 0, 0, 0);
		border: 5px solid #FFF;
		border-radius: 0px;
		color: #FFF;

		transition: .3s;
	}

	#contacto .vacante:hover,
	#contacto .contact:hover{
		background-color: #FFF;
		color: #2c2c2c;
	}

	@media (max-width: 440px){
		#contacto .title-vnkg {
			font-size: 2em;
			line-height: 1;
			font-weight: 500;
		}

		#contacto .text-general{
			font-size: 1.1em;
			font-weight: 400;
		}

		#contacto .text-general b{
			font-size: 0.8em;
		}

		#contacto .vacante,
		#contacto .contact{
			width: 70%;
			height: 70px;

			font-weight: 500;
		}
	}

/* -------------------------------------------------------------------------- */


/* --------------------------------- footer --------------------------------- */
	footer{
		background-color: #2c2c2c;

		-webkit-box-shadow: 0px 7px 20px -4px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 7px 20px -4px rgba(0,0,0,0.75);
		box-shadow: 0px 7px 20px -4px rgba(0,0,0,0.75);
	}
	.logoFooter{
		display: block;
		width: 100%;
		height: 150px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url('/img/logo_w.svg');
	}

	.box-footer-items{
		display: flex;
		justify-content: center;
		align-items: start;
		flex-flow: column wrap;
	}

	footer a{
		color: #FFF;
		text-decoration: none;
		cursor: pointer;
	}
	footer a:hover{
		color: #ffffffbd;
	}

	.icon-rs{
		font-size: 3em;
	}

	.box-info{
		min-height: 190px;
		width: 100%;
		background-color: #45c5d9;
		color: #fff;
		padding: 0 50px;
		font-weight: 700 !important;

		display: flex;
		justify-content: center;
		align-items: center;
	}
	.box-info p{
		padding: 25px 0 25px 60px;
		font-weight: 700 !important;
		font-size: 1.3em;
		text-decoration: none;
		position: relative;
	}

	.box-info p::after{
		position: absolute;
		content: '';
		left: 0px;
		top: 0;
		bottom: 0;
		width: 50px;
		background-size: contain;
		background-image: url('/img/location.svg');
		background-position: center;
		background-repeat: no-repeat;
	}

	.align-direccion{
		display: block;
		padding-top: 27px;
		width: 100%;
	}

	@media (max-width: 990px) {
		.align-direccion{
			display: block;
			padding-top: 0px;
			width: 100%;
		}
	}

	@media (max-width: 575px) {
		.logoFooter{
			width: 80%;
			margin: auto;
		}
		.text-copyright{
			text-align: center;
		}
		.box-footer-items{
			align-items: center;
		}
	}

	@media (max-width: 440px) {
		.box-info{
			min-height: 120px;
		}
		.box-info p{
			font-size: 1.1em;
		}
		.box-info p::after{
			width: 40px;
		}
		.logoFooter{
			width: 70%;
			margin: auto;
		}
		.text-copyright{
			font-size: 1.1em;
		}
		.icon-rs{
			font-size: 2.5em;
		}
	}

/* -------------------------------------------------------------------------- */


/* ------------------------------ Menu idiomas ------------------------------ */


	// @keyframes showMenuLang {
	// 	from {
	// 		filter: opacity(0);
	// 	}
	// 	to {
	// 		filter: opacity(1);
	// 	}
	// }

	// #circularMenu1{
	// 	filter: opacity(0);
	// 	animation-name: showMenuLang;
  	// 	animation-duration: .5s;
	// 	animation-delay: 2s;
	// }

	#circularMenu1 .flag{
		width: 43px;
		height: 43px;
		margin: 0;
		display: block;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 50%;
	}

	#circularMenu1 .flag.flag-1{
		background-image: url('/img/lang/es.svg');
	}
	#circularMenu1 .flag.flag-2{
		background-image: url('/img/lang/en.svg');
	}
	#circularMenu1 .flag.flag-3{
		background-image: url('/img/lang/cn.svg');
	}
	#circularMenu1 .flag.flag-4{
		background-image: url('/img/lang/br.svg');
	}

	.circular-menu {
		position: fixed;
		bottom: 1em;
		right: 1em;
		z-index: 1061;

		transition-delay: .5s;
		transition-duration: .5s;
		transition-property: all;
		transition-timing-function: ease;
	}

	.circular-menu .floating-btn {
		display: block;
		width: 3.5em;
		height: 3.5em;
		border-radius: 50%;
		background-color: #45c5d9;
		box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, .26);
		color: hsl(0, 0%, 100%);
		text-align: center;
		line-height: 3.9;
		cursor: pointer;
		outline: 0;
	}

	.circular-menu.active .floating-btn {
		box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .3);
	}

	.circular-menu .floating-btn:active {
		box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, .4);
	}

	.circular-menu .floating-btn i {
		font-size: 1.3em;
		transition: transform .2s;
	}

	.circular-menu.active .floating-btn i {
		transform: rotate(-0deg);
	}

	.circular-menu:after {
		display: block;
		content: ' ';
		width: 3.5em;
		height: 3.5em;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -2;
		background-color: hsl(4, 98%, 60%);
		transition: all .3s ease;
	}

	.circular-menu.active:after {
		transform: scale3d(5.5, 5.5, 1);
		transition-timing-function: cubic-bezier(.68, 1.55, .265, 1);
	}

	.circular-menu .items-wrapper {
		padding: 0;
		margin: 0;
	}

	.circular-menu .menu-item {
		position: absolute;
		top: .2em;
		right: .2em;
		z-index: -1;
		display: block;
		text-decoration: none;
		color: hsl(0, 0%, 100%);
		font-size: 1em;
		width: 3em;
		height: 3em;
		border-radius: 50%;
		text-align: center;
		line-height: 3;
		background-color: hsla(0,0%,0%,.1);
		transition: transform .3s ease, background .2s ease;
	}

	.circular-menu .menu-item:hover {
		background-color: hsla(0,0%,0%,.3);
	}

	.circular-menu.active .menu-item {
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}

	.circular-menu.active .menu-item:nth-child(1) {
		transform: translate3d(1em,-7em,0);
	}

	.circular-menu.active .menu-item:nth-child(2) {
		transform: translate3d(-3.5em,-6.3em,0);
	}

	.circular-menu.active .menu-item:nth-child(3) {
		transform: translate3d(-6.5em,-3.2em,0);
	}

	.circular-menu.active .menu-item:nth-child(4) {
		transform: translate3d(-7em,1em,0);
	}

	/**
	 * The other theme for this menu
	 */

	.circular-menu.circular-menu-left {
		right: auto;
		left: 1em;
	}

	.circular-menu.circular-menu-left .floating-btn {
		background-color: #45c5d9;
	}

	.circular-menu.circular-menu-left:after {
		background-color: #6bbad577;
	}

	.circular-menu.circular-menu-left.active .floating-btn i {
		transform: rotate(0deg);
	}

	.circular-menu.circular-menu-left.active .menu-item:nth-child(1) {
		transform: translate3d(2em,-5em,0);
	}

	.circular-menu.circular-menu-left.active .menu-item:nth-child(2) {
		transform: translate3d(5.6em,-1.3em,0);
	}

	.circular-menu.circular-menu-left.active .menu-item:nth-child(3) {
		transform: translate3d(6.5em,-3.2em,0);
	}

	.circular-menu.circular-menu-left.active .menu-item:nth-child(4) {
		transform: translate3d(7em,1em,0);
	}

/* -------------------------------------------------------------------------- */

@media (max-width: 991px) {
	.w-75{
		width: 80% !important;
	}
	#nosotros .title-vnkg{
		text-align: center;
	}
	.bg-maderas{
		background-image: url('/img/bg-tiktoks-v.webp');
	}
}

@media (max-width: 767px) {
	.w-75{
		width: 100% !important;
	}
}

@media (max-width: 767px){
	#nosotros .title-vnkg{
		text-align: center;
	}
}

@media (max-width: 576px){
	#nosotros .container {
		padding-right: 25px !important;
		padding-left: 25px !important;
	}
}

.swal2-vkng .swal2-popup{
	padding: 0;
	width: 500px;
	border-radius: 0;
}
.swal2-html-container.modal-vkng {
	margin: 0;
}


/* ---------------------------- Seccion contacto ---------------------------- */

#contact {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
}

#contact .spacer-border{
	max-width: 500px;
	margin: 9px;
	padding: 25px;
	border: 2px solid #45c5d9;
}

#contact .box-title{
	width: 100%;
	margin: 30px auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

#contact img.title{
	width: 60%;
	margin-left: 39px;
}

#contact button.btn-submit{
	width: 50%;
	height: 50px;
	display: block;
	border: 0;
	background-color: #ffffff00;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

#contact .form-group{
	margin-bottom: 6px;
}

#contact .form-group label{
	color: #4B10E9;
	font-weight: 500;
}

#contact .form-group input{
	border: 2px solid #45c5d9;
	border-radius: 0;
	background-color: #FFF;
}

#contact .form-group .budget_min{
	margin-right: 6px;
}

#contact .form-group .budget_max{
	margin-left: 6px;
}

#contact .form-group .usd{
	color: #4B10E9;
	font-weight: 500;
	padding: 8px 0 8px 8px;
}

#contact .disclaimer{
	margin-top: 30px;
	display: inherit;
	font-size: .8em;
	color: #8f8f8f;
	line-height: 1;
}

#contact .text{
	margin-top: 0px;
	display: inherit;
	font-size: .8em;
	color: #8f8f8f;
	line-height: 1;
	z-index: 10;
}


/* ---------------------------- Seccion Job ---------------------------- */

#job_section {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
}

#job_section .spacer-border{
	max-width: 500px;
	margin: 9px;
	padding: 25px;
	border: 2px solid #45c5d9;
}

#job_section .box-title{
	width: 100%;
	margin: 30px auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

#job_section img.title{
	width: 60%;
}

#job_section button.btn-submit{
	width: 50%;
	height: 50px;
	display: block;
	border: 0;
	background-color: #ffffff00;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

#job_section .form-group{
	margin-bottom: 6px;
}

#job_section .form-group label{
	color: #4B10E9;
	font-weight: 500;
}

#job_section .form-group input,
#job_section .form-group select,
#job_section .form-group textarea{
	border: 2px solid #45c5d9;
	border-radius: 0;
	background-color: #FFF;
}

#job_section .form-group .budget_min{
	margin-right: 6px;
}

#job_section .form-group .budget_max{
	margin-left: 6px;
}

#job_section .form-group .usd{
	color: #4B10E9;
	font-weight: 500;
	padding: 8px 0 8px 8px;
}

#job_section .disclaimer{
	margin-top: 30px;
	display: inherit;
	font-size: .8em;
	color: #8f8f8f;
	line-height: 1;
}

#job_section .text{
	margin-top: 0px;
	display: inherit;
	font-size: .8em;
	color: #8f8f8f;
	line-height: 1;
	z-index: 10;
}


/* ----------------------------- Call to action ----------------------------- */

#circularMenu1.top{
	bottom: 7.5em !important;
}

#cta {
	width: 100%;
	height: 90px;
	padding: 15px 5px;
	bottom: -90px;
	left: 0;
	position: fixed;
	text-align: center;
	background-color: #fff;
	z-index: 1062;
	transition-delay: .5s;
	transition-duration: .5s;
	transition-property: all;
	transition-timing-function: ease;

	-webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.5);
}
#cta.active {
	bottom: 0px;
}
#cta .btn{
	border-radius: 2rem;
	background-color: #4c11ea !important;
	border-color: #4c11ea !important;
	color: #FFF !important;
	font-weight: 500;
}
#cta p{
	font-size: .8em;
	margin-top: 5px;
}
